import React, { useEffect, useState } from 'react';

const PowerBIReport = () => {
  const [iframeHeight, setIframeHeight] = useState('100%');

  useEffect(() => {
    const updateIframeHeight = () => {
      const headerHeight = document.querySelector('header').offsetHeight;
      const footerHeight = document.querySelector('footer').offsetHeight;
      const availableHeight = window.innerHeight - headerHeight - footerHeight;
      setIframeHeight(`${availableHeight}px`);
    };

    updateIframeHeight();
    window.addEventListener('resize', updateIframeHeight);

    return () => {
      window.removeEventListener('resize', updateIframeHeight);
    };
  }, []);

  return (
    <div className="flex flex-col h-screen bg-white shadow-lg">
      {/* Container for text before the iframe */}

  

      {/* Container for the Power BI iframe */}
      <div className="flex-grow flex">
        <div className="w-full p-6 bg-white rounded-lg shadow-lg">
          <iframe
            title="Power BI Report"
            src="https://app.powerbi.com/view?r=eyJrIjoiZjViMTRmNTEtMThhYy00ZDJkLTk5MDYtZWVkZDg5NWM5NjI0IiwidCI6Ijc1NzY3ZWFlLTY5ZDQtNDM0Yi1hOWI3LWZkNjVmYjJiOTk1MyJ9"
            width="100%"
            height={iframeHeight}
            style={{ border: 'none', minHeight: '100%' }}
            allowFullScreen
          />
        </div>
      </div>
    </div>
  );
};

export default PowerBIReport;
