import React from 'react';
import { motion } from 'framer-motion';
import logo from './data/logo.png'; // Adjust the path as needed


const IntroAnimation = ({ onAnimationComplete }) => {
    return (
      <motion.div
        initial={{ opacity: 1 }}
        animate={{ opacity: 0 }}
        transition={{ duration: 0.5, delay: 1.5 }}
        onAnimationComplete={onAnimationComplete}
        className="fixed inset-0 bg-black flex items-center justify-center z-50"
      >
        <motion.img
          src={logo}
          alt="Swish Analytics Logo"
          initial={{ scale: 0.5, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          transition={{ duration: 1 }}
          className="w-4/5 h-auto max-w-5xl"
        />
      </motion.div>
    );
  };
  
  export default IntroAnimation;