import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        <footer className="bg-black text-white py-4 mt-auto"> {/* Reduced py-8 to py-4 */}
            <div className="container mx-auto px-4">
                <div className="grid md:grid-cols-3 gap-8">
                    <div>
                        <h3 className="text-xl font-semibold mb-2">Swish Analytics</h3> {/* Reduced margin */}
                        <p>ABN 61679396126</p>
                        <p>info@swishanalytics.com.au</p>
                    </div>
                    <div>
                        <h3 className="text-xl font-semibold mb-2">Quick Links</h3> {/* Reduced margin */}
                        <ul>
                            <li><Link to="/" className="hover:text-cyan-400 transition duration-300">Home</Link></li>
                            <li><Link to="/services" className="hover:text-cyan-400 transition duration-300">Services</Link></li>
                            <li><Link to="/about" className="hover:text-cyan-400 transition duration-300">About</Link></li>
                            <li><Link to="/contact" className="hover:text-cyan-400 transition duration-300">Contact</Link></li>
                        </ul>
                    </div>
                    <div>
                        <h3 className="text-xl font-semibold mb-2">Connect With Us</h3> {/* Reduced margin */}
                        {/* Add social media icons or links here */}
                    </div>
                </div>
                <div className="text-center mt-4 pt-4 border-t border-gray-700"> {/* Reduced margins */}
                    <p>© 2024 Swish Analytics. All rights reserved.</p>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
