import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';

// Import logos
import sqlLogo from '../data/sql.png';
import aws from '../data/aws.png';
import azure from '../data/azure.png';
import teradata from '../data/teradata.png';
import oracle from '../data/oracle.png';
import engineer from '../data/engineer.png';
import automation from '../data/automation.png';

import python from '../data/python.png';
import excel from '../data/excel.png';
import r from '../data/r.png';
import vscode from '../data/vscode.png';

import tableau from '../data/tableau.png';
import vsstudio from '../data/vsstudio.png';
import alteryx from '../data/alteryx.png';
import powerbi from '../data/powerbi.png';
import powerautomate from '../data/powerautomate.png';
import Preparation from '../data/prepare.png';
import sharepoint from '../data/sharepoint.png';
import google from '../data/google.png';
import reporting from '../data/reporting.png';

const ServiceCard = ({ title, description, image, tools }) => (
  <motion.div
    className="bg-white p-12 rounded-lg shadow-lg w-full max-w-6xl mx-auto"
    initial={{ opacity: 0, y: 50 }}
    animate={{ opacity: 1, y: 0 }}
    exit={{ opacity: 0, y: -50 }}
    transition={{ duration: 0.5 }}
  >
    <div className="flex flex-col items-center">
      <img src={image} alt={title} className="w-32 h-32 object-contain mb-4 lg:w-48 lg:h-48" />
      <h3 className="text-4xl font-semibold mb-6 text-center">{title}</h3>
      <div className="text-gray-700 mb-8 text-xl text-left max-w-3xl lg:text-2xl">
        {description.map((desc, index) => (
          <p key={index} className="mb-4">
            <span className="font-semibold">{desc.title}:</span> {desc.text}
          </p>
        ))}
      </div>
      <div className="flex flex-wrap justify-center items-center gap-6 sm:gap-4 lg:gap-12">
        {tools.map((tool, index) => (
          <div 
            key={index} 
            className="flex justify-center items-center w-24 h-24 sm:w-20 sm:h-20 lg:w-28 lg:h-28 bg-white rounded-lg"
          >
            <img 
              src={tool} 
              alt="Tool logo" 
              className="max-w-full max-h-full object-contain" 
            />
          </div>
        ))}
      </div>
    </div>
  </motion.div>
);


function Services() {
  const [currentIndex, setCurrentIndex] = useState(0);

  const services = [
    {
      title: "Data Engineering",
      description: [
        { title: "Optimising Data Models", text: "We enhance your data structures for faster access and better system performance, ensuring your data is always at its best." },
        { title: "Data Integration with ETL/ELT Pipelines", text: "Our expertise in tools like SSIS and Azure ADF allows us to seamlessly integrate and transform data from multiple sources into a cohesive, ready-to-use format." },
        { title: "API Integrations", text: "We enable efficient data exchange by connecting your systems to external services through robust API integrations, enhancing functionality and data flow." }
      ],
      image: engineer,
      tools: [azure, aws, sqlLogo, teradata, oracle]
    },
    {
      title: "Automation",
      description: [
        { title: "Process Automation", text: "Leverage our expertise in tools like Python, macros, and Power Automate to automate repetitive tasks, streamline workflows, and boost operational efficiency." },
        { title: "Process Optimisation", text: "We refine your processes using advanced programming tools to deliver faster, more reliable results, maximizing productivity and minimizing errors." },

      ],
      image: automation,
      tools: [python, r, vscode, excel]
    },

    {
      title: "Data Preparation",
      description: [
        { title: "Optimising Data for Reporting", text: "We refine your data to ensure it’s perfectly suited for clear and insightful reporting." },
        { title: "Creating Data Relationships and Integrations", text: " Our team establishes robust data connections across systems and sources, providing a comprehensive view that supports cohesive data analysis." },
        { title: "Data Quality Management", text: "We maintain high standards for data accuracy and reliability, ensuring your data is always fit for purpose and supports informed decision-making." },
      ],
      image: Preparation,
      tools: [alteryx, vsstudio, powerautomate, vscode]
    },
    {
      title: "Reporting & Insights",
      description: [
        { title: "Interactive Reporting", text: "We offer dynamic, interactive reporting solutions using tools like Power BI and Tableau, allowing for real-time data exploration and visualization that drives deeper business insights." },
        { title: "Performance Management & Insight", text: "Our solutions empower organizations with the tools and insights needed for effective performance management, helping achieve strategic goals through data-driven analysis.." },
        { title: "Customised Reporting Solutions", text: "Creating tailored reporting solutions that cater to the unique needs of both executive management and operational teams, facilitating informed decision-making at every level of the organisation." },
      ],
      image: reporting,
      tools: [powerbi, tableau, google, sharepoint]
    }
  ];

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % services.length);
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + services.length) % services.length);
  };

  return (
    <div className="bg-gray-100 py-10 min-h-screen flex flex-col justify-center"> {/* Adjusted top padding */}
      <div className="container mx-auto px-6">
        <h2 className="text-5xl font-bold text-black mb-12 text-center">Our Services</h2> {/* Reduced bottom margin */}
        <div className="relative flex justify-center">
          <AnimatePresence mode="wait">
            <ServiceCard key={currentIndex} {...services[currentIndex]} />
          </AnimatePresence>
          <button
            onClick={prevSlide}
            className="absolute top-1/2 left-0 transform -translate-y-1/2 bg-white p-4 rounded-full shadow-md z-10"
          >
            <FaChevronLeft className="text-gray-600 text-3xl" />
          </button>
          <button
            onClick={nextSlide}
            className="absolute top-1/2 right-0 transform -translate-y-1/2 bg-white p-4 rounded-full shadow-md z-10"
          >
            <FaChevronRight className="text-gray-600 text-3xl" />
          </button>
        </div>
        <div className="flex justify-center mt-12"> {/* Reduced margin-top */}
          {services.map((_, index) => (
            <button
              key={index}
              onClick={() => setCurrentIndex(index)}
              className={`w-5 h-5 rounded-full mx-2 ${
                index === currentIndex ? 'bg-blue-500' : 'bg-gray-300'
              }`}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default Services;
