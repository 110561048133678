import React from 'react';
import { Link } from 'react-router-dom';
import { motion, useAnimation, useInView } from 'framer-motion';
import { useEffect, useRef } from 'react';

const lineVariants = {
  hidden: { pathLength: 0 },
  visible: { 
    pathLength: 1, 
    transition: { 
      duration: 2.5, 
      ease: "easeInOut" 
    } 
  }
};

const pointVariants = {
  hidden: { opacity: 0 },
  visible: { 
    opacity: 1, 
    transition: { 
      duration: 0.5
    } 
  }
};

const Home = () => {
  const controls = useAnimation();
  const ref = useRef(null);
  const isInView = useInView(ref, { once: false });

  const points = [
    {x: 50, y: 200},
    {x: 125, y: 150},
    {x: 200, y: 225},
    {x: 275, y: 250},
    {x: 350, y: 175},
    {x: 425, y: 100},
    {x: 500, y: 50}
  ];

  const linePath = `M${points.map(p => `${p.x},${p.y}`).join(' L')}`;

  useEffect(() => {
    if (isInView) {
      controls.start("visible");
    } else {
      controls.start("hidden");
    }
  }, [controls, isInView]);

  return (
    <div className="bg-white min-h-screen flex flex-col">
      <div className="flex-grow container mx-auto px-4 py-16">
        <div className="flex flex-col lg:flex-row items-center justify-between">
          <div className="lg:w-1/2 pr-8">
            <h1 className="text-5xl font-bold text-gray-900 mb-10">
              Reinventing Business Intelligence
            </h1>
            <div className="space-y-6">
              <p className="text-lg text-gray-700 leading-relaxed">
              At Swish Analytics, we are redefining the traditional reporting model for small and medium-sized businesses. While many companies are familiar with Software as a Service (SaaS), the concept of Business Intelligence Outsourcing as a Service (BIOS) remains relatively unexplored. We aim to change this by offering a comprehensive suite of data services, including data engineering, data modeling, data cleansing, automation, and reporting.
              </p>
              <p className="text-lg text-gray-700 leading-relaxed">
              For smaller businesses, it can be challenging to afford a dedicated team to implement and manage these functions on an ongoing basis. Additionally, finding professionals with the right mix of technical and business skills can be difficult. At Swish Analytics, we transform your organization's data into a strategic asset, enhancing performance management and optimizing decision-making processes.
              </p>
              <p className="text-lg text-gray-700 leading-relaxed">
              With over 30 years of collective experience in reporting and business intelligence, our team is dedicated to helping business leaders reclaim valuable time and focus on making data-driven decisions. We provide customized data engineering, automation, and business intelligence solutions designed to meet the specific needs of our clients.
              </p>
            </div>
            <div className="mt-10">
              <Link to="/services" className="bg-[#41b8d5] text-white py-3 px-8 rounded-full hover:bg-cyan-500 transition duration-300 text-lg font-semibold inline-block">
                Explore Our Services
              </Link>
            </div>
          </div>
          <div className="lg:w-1/2 mt-12 lg:mt-0 flex items-center justify-center" ref={ref}>
            <svg width="100%" height="300" viewBox="0 0 550 300" fill="none" xmlns="http://www.w3.org/2000/svg">
              <motion.path
                d={linePath}
                stroke="#41b8d5"
                strokeWidth="4"
                fill="none"
                variants={lineVariants}
                initial="hidden"
                animate={controls}
              />
              {points.map((point, i) => (
                <motion.circle
                  key={i}
                  cx={point.x}
                  cy={point.y}
                  r="6"
                  fill="#41b8d5"
                  variants={pointVariants}
                  initial="hidden"
                  animate={controls}
                  transition={{ delay: i * 0.3 }}
                />
              ))}
            </svg>
          </div>
        </div>
      </div>

      <div className="bg-gray-100 py-20">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-bold text-gray-900 mb-12 text-center">
            Why Choose Swish Analytics?
          </h2>
          <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-10">
            {[
              { title: "Strategic BI Investment", description: "Gain access to a diverse team of skilled professionals without increasing headcount." },
              { title: "Expertise on Demand", description: "Leverage 30+ years of collective experience in Business Intelligence." },
              { title: "Data-Driven Decisions", description: "Make informed, data-driven decisions to effectively run your business." },
              { title: "Cost-Effective Solutions", description: "Implement tailored Business Intelligence solutions for your needs." },
            ].map((item, index) => (
              <div key={index} className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition duration-300">
                <h3 className="text-xl font-semibold mb-4 text-gray-900">{item.title}</h3>
                <p className="text-gray-600">{item.description}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
