import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import logo from './data/logo.png';
import { Menu, ChevronDown, Mail } from 'lucide-react';
import MobileWebRecommendation from './MobileWebRecommendation';

const Header = () => {
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isToolsOpen, setIsToolsOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [showRecommendation, setShowRecommendation] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const closeMenus = () => {
    setIsMenuOpen(false);
    setIsToolsOpen(false);
  };

  const handleNavigation = (path) => {
    navigate(path);
    closeMenus();
    if (isMobile && path === '/tools/sql-analysis') {
      setShowRecommendation(true);
    }
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const toggleTools = () => {
    setIsToolsOpen(!isToolsOpen);
  };

  const handleEmailClick = () => {
    window.location.href = 'mailto:info@swishanalytics.com.au';
    closeMenus();
  };

  return (
    <>
      <AnimatePresence>
        {showRecommendation && (
          <MobileWebRecommendation onDismiss={() => setShowRecommendation(false)} />
        )}
      </AnimatePresence>
      <header className="bg-black shadow-md">
        <div className="container mx-auto px-4 py-3 flex justify-between items-center">
          <div className="flex items-center">
            <img 
              src={logo} 
              alt="Swish Analytics Logo" 
              className="h-16 w-auto object-contain"
            />
          </div>
          {isMobile ? (
            <div className="relative">
              <button onClick={toggleMenu} className="text-white">
                <Menu size={28} />
              </button>
              {isMenuOpen && (
                <nav className="absolute right-0 mt-2 w-48 bg-black rounded-md shadow-lg py-2 z-10">
                  <Link to="/" className="block px-4 py-2 text-white hover:bg-gray-800" onClick={() => handleNavigation('/')}>Home</Link>
                  <button onClick={handleEmailClick} className="w-full text-left px-4 py-2 text-white hover:bg-gray-800 flex items-center">
                    <Mail size={16} className="mr-2" /> Contact
                  </button>
                  <button onClick={() => handleNavigation('/services')} className="w-full text-left px-4 py-2 text-white hover:bg-gray-800">Services</button>
                  <button onClick={() => handleNavigation('/sample-report')} className="w-full text-left px-4 py-2 text-white hover:bg-gray-800">Sample Report</button>
                  <div className="relative">
                    <button onClick={toggleTools} className="w-full text-left px-4 py-2 text-white hover:bg-gray-800 flex justify-between items-center">
                      Tools <ChevronDown size={16} />
                    </button>
                    {isToolsOpen && (
                      <div className="pl-4">
                        <button onClick={() => handleNavigation('/tools/sql-analysis')} className="block w-full text-left px-4 py-2 text-white hover:bg-gray-800">SQL Tools</button>
                      </div>
                    )}
                  </div>
                </nav>
              )}
            </div>
          ) : (
            <nav className="flex items-center space-x-6">
              <Link to="/" className="text-white hover:text-cyan-400 transition duration-300" onClick={closeMenus}>Home</Link>
              <button onClick={handleEmailClick} className="text-white hover:text-cyan-400 transition duration-300 flex items-center">
                <Mail size={16} className="mr-1" /> Contact
              </button>
              <button onClick={() => handleNavigation('/services')} className="text-white hover:text-cyan-400 transition duration-300">Services</button>
              <button onClick={() => handleNavigation('/sample-report')} className="text-white hover:text-cyan-400 transition duration-300">Sample Report</button>
              <div className="relative">
                <button onClick={toggleTools} className="bg-[#41b8d5] text-white px-4 py-2 rounded-md hover:bg-cyan-400 transition duration-300 flex items-center">
                  Tools <ChevronDown size={16} className="ml-1" />
                </button>
                {isToolsOpen && (
                  <div className="absolute right-0 mt-2 w-48 bg-black rounded-md shadow-lg py-2 z-10">
                    <button onClick={() => handleNavigation('/tools/sql-analysis')} className="block w-full text-left px-4 py-2 text-white hover:bg-gray-800">SQL Tools</button>
                  </div>
                )}
              </div>
            </nav>
          )}
        </div>
      </header>
    </>
  );
};

export default Header;