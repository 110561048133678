import React from 'react';
import { motion } from 'framer-motion';

const MobileWebRecommendation = ({ onDismiss }) => {
  return (
    <motion.div
      initial={{ opacity: 0, y: -50 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -50 }}
      transition={{ duration: 0.5 }}
      className="fixed inset-x-0 top-0 z-50 bg-black bg-opacity-90 text-white p-4 text-center"
    >
      <p className="text-lg font-bold mb-2">For the best experience</p>
      <p className="mb-4">This page is optimized for larger screens or desktop devices.</p>
      <motion.button
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
        onClick={onDismiss}
        className="bg-cyan-500 text-white px-4 py-2 rounded-md"
      >
        Got it
      </motion.button>
    </motion.div>
  );
};

export default MobileWebRecommendation;